<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Periode"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl+'?status=sent'"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
      >
        <template #cell(status)="data">
          <span
            class="badge "
            :class="data.item.status == 'received'?'bg-primary':'bg-warning'"
          >
            {{ data.item.status }}
          </span>
        </template>

        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
          <!-- <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button> -->
        </template>
 
        <template v-slot:buttonaddaction>
          <b-button
            v-b-modal="'modal-select'"
            class="ml-1"
            size="sm"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" />
            Tambah
          </b-button>
        </template>
      </basetable>
      <b-modal
        :id="'modal-select'"
        centered
        title="Tambah Pengiriman"
        hide-footer
      >
        <div>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <form-v-select
                    v-model="mutation"
                    :selected="mutation"
                    rules="required"
                    label="Pilih Nomor"
                    item_text="number"
                    placeholder="Select Value"
                    :options="optionMutation"
                  />
                </b-col>
                <b-col cols="12">
                  <hr>
                  <b-form-group
                    label-cols-md="3"
                    label=""
                  >
                    <b-button
                      style="float: right;"
                      class="mr-1"
                      size="sm"
                      variant="primary"
                      type="button"
                      @click.prevent="validationForm"
                    >
                      <span v-if="loading">
                        <b-spinner small />
                        Loading...
                      </span>
                      <span v-else>Tambah</span>
                    </b-button>
                    <b-button
                      style="float: right;"
                      class="mr-1"
                      size="sm"
                      variant="warning"
                      type="button"
                      @click.prevent="hideForm"
                    >
                      <span>Batal</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol, BInputGroup, BInputGroupAppend, BModal, BSpinner, BForm } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'

export default {
  components: {
    ValidationObserver,
    basetable,
    BCard,
    BButton,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    FormVSelect,
    BForm,
    BSpinner
  },
  data() {
    return {
      dataurl:"/vehicle-mutation",
      baseroute:"mutation-out",
      title:"Migrasi Kendaraan",
      fields: [
        { key: 'destination.name', label: 'Cabang Requestor', sortable: true },
        { key: 'origin.name', label: 'Cabang Pengirim', sortable: true },
        { key: 'request_date', label: 'Tgl Permintaan', sortable: true },
        { key: 'number', label: 'No Permintaan', sortable: true },
        { key: 'vehicles_count', label: 'Qty Kendaraan', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      showFilter : false,
      filter :[],
      active:null,
      date_start:"",
      date_end:"",
      optionMutation:[],
      mutation:null,
      loading:false
    }
  },
  mounted(){
    this.fetchDataMutation()
  },
  methods:{
    show(){
      if(this.date_start != "" && this.date_end != "")
        this.filter['between[request_date]']=this.date_start+","+this.date_end

      this.$refs.basetable.fetchData()
    },
    reset(){
      this.date_start = ""
      this.date_end = ""
      this.filter['between[request_date]'] = null
      this.show()
    },
    fetchDataMutation(){
      this.$http.get(this.dataurl+'?status=approved').then(res=>{
        this.optionMutation = res.data.data
      })
    },
    hideForm(){
      this.$bvModal.hide('modal-select')
      this.mutation = null
      this.$refs.simpleRules.reset()
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          this.loading = true
          this.$http.put(this.dataurl+'/'+this.mutation.id+'/send')
          .then(()=>{
            this.successSubmit()
          this.$store.dispatch('sendMessage', '1')
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }else{
          return this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.$refs.basetable.fetchData()
      this.hideForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    }
  }
}
</script>
<style lang="">

</style>
